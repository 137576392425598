import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout/layoutWithContainer.js";
import SEO from "../../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Prisma Cloud" mdxType="SEO" />
    <h1 {...{
      "id": "prisma-cloud"
    }}>{`Prisma Cloud`}</h1>
    <hr />
    {
      /* ## Table of contents ## */
    }
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`Palo Alto Networks has created its newest comprehensive cloud security suite called Prisma,
designed to consistently govern access, protect data and secure applications.`}</p>
    <div {...{
      "className": "gatsby-resp-iframe-wrapper",
      "style": {
        "paddingBottom": "56.25%",
        "position": "relative",
        "height": "0",
        "overflow": "hidden"
      }
    }}>{` `}<iframe parentName="div" {...{
        "src": "https://www.youtube.com/embed/HQqldy2Pqxw",
        "frameBorder": "0",
        "allow": "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
        "allowFullScreen": true,
        "style": {
          "position": "absolute",
          "top": "0",
          "left": "0",
          "width": "100%",
          "height": "100%"
        }
      }}></iframe>{` `}</div>
    <br /><br />
    <h2 {...{
      "id": "the-four-components-of-prisma"
    }}>{`The four components of Prisma`}</h2>
    <hr />
    <h3 {...{
      "id": "prisma-access"
    }}>{`Prisma Access`}</h3>
    <p>{`Secures access to the cloud for branch offices and mobile users anywhere in the world with a scalable, cloud-native architecture.`}</p>
    <br /><br />
    <h3 {...{
      "id": "prisma-public-cloud"
    }}>{`Prisma Public Cloud`}</h3>
    <p>{`Provides continuous visibility, security, and compliance monitoring across public multi-cloud deployments`}</p>
    <br /><br />
    <h3 {...{
      "id": "prisma-saas"
    }}>{`Prisma SaaS`}</h3>
    <p>{`Is a `}<strong parentName="p">{`multi-mode cloud access security broker (CASB)`}</strong>{` service that safely enables SaaS application adoption.`}</p>
    <p>{`It provides advanced capabilities in risk discovery, adaptive access control, data loss prevention, compliance assurance,
data governance, user behavior monitoring, and advanced threat prevention.`}</p>
    <br /><br />
    <h3 {...{
      "id": "vm-series"
    }}>{`VM-Series`}</h3>
    <p>{`Is the virtualized form factor of the `}<strong parentName="p">{`Palo Alto Networks Next-Generation Firewall`}</strong>{` that can be deployed in private
and public cloud computing environments, including Amazon Web Services, GCP, Microsoft Azure, Oracle Cloud, Alibaba Cloud, and VMware NSX.`}</p>
    <br /><br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      